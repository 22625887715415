import React, { useEffect, useState } from "react"
import ReactGA from "react-ga"
import Recaptcha from "react-recaptcha"
import Button from "../components/button"
import Column from "../components/column"
import ErrorBoundary from "../components/errorBoundry"
import FormInput from "../components/input"

import { NoSideBarLayout } from "../components/layouts"
import Row from "../components/row"
import SEO from "../components/seo"
import { HubSpotContactFormBody, submit } from "../services/hubspot"
import { COMMUNICATION_CONSENT_TEXT, CONSENT_TO_COMMUNICATE_CHECKBOX_TEXT, CONSENT_TO_PROCESS_CHECKBOX_TEXT, PRIVACY_TEXT, PROCESS_CONSENT_TEXT } from "../strings/hubspot"
import setFormControl from "../utils/setFormControl"

const SponsorInterestPage = () => {
  const [captchaResponse, setCaptchaResponse] = useState("")
  const [firstName, setFirstName] = useState("")
  const [lastName, setLastName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [company, setCompany] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [communicationConsent, setCommunicationConsent] = useState(false)
  const [processConsent, setProcessConsent] = useState(false)
  const [error, setError] = useState(null)
  const [submitStatus, setSubmitStatus] = useState<
    "loading" | "success" | "none"
  >("none")

  useEffect(() => {
    console.log('communication consent', communicationConsent);
  }, [communicationConsent])

  const toggleCommunicationConsent = () => setCommunicationConsent(!communicationConsent);
  const toggleProcessConsent = () => setProcessConsent(!processConsent);

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (!firstName) {
      setError("Please enter your first name.")
      return
    }

    if (!lastName) {
      setError("Please enter your last name.")
      return
    }

    if (!email) {
      setError("Please enter a valid email address.")
      return
    }

    if (!phoneNumber) {
      setError("Please enter a valid phone number.")
      return
    }

    if (!company) {
      setError("Please enter your company name.")
      return
    }

    if (!message) {
      setError("Please enter your message.")
      return
    }

    if (!processConsent) {
      setError('You must consent to data processing.')
      return
    }

    if (!captchaResponse) {
      setError("You must complete the captcha.")
      return
    }

    setError(null)

    try {
      setSubmitStatus("loading")
      await submit({
        firstname: firstName,
        lastname: lastName,
        email,
        phone: phoneNumber,
        company,
        message,
      }, processConsent, communicationConsent, PROCESS_CONSENT_TEXT, COMMUNICATION_CONSENT_TEXT)

      ReactGA.event({
        category: "Forms",
        action: "Submitted",
        label: "Sponsor Interest Form Submitted",
      })

      setSubmitStatus("success")
      return
    } catch (e) {
      console.error(e)
      setError(e.message)
      setSubmitStatus("none")
      ReactGA.exception({
        description: "Failed to submit sponsor interest form",
        fatal: false,
      })
      return
    }
  }

  return (
    <NoSideBarLayout>
      <SEO title="Work with Us" />
      <h1>Work with Us</h1>
      <Row>
        <Column large={7}>
          <p>
            Use this form if you are a from a company and are interested in working with us commercially.
          </p>
          {error && <p className="form-error">{error}</p>}
          {submitStatus === "success" && (
            <p className="form-success">Contact Form Submitted Successfully.</p>
          )}
          <form onSubmit={onSubmit}>
            <FormInput
              id="sponsor-form-first-name"
              label="First Name"
              type="text"
              name="first_name"
              required
              value={firstName}
              onChange={setFormControl(setFirstName)}
            />
            <FormInput
              id="sponsor-form-last-name"
              label="Last Name"
              type="text"
              name="last_name"
              required
              value={lastName}
              onChange={setFormControl(setLastName)}
            />
            <FormInput
              id="sponsor-form-email"
              label="Email Address"
              type="email"
              name="email"
              required
              value={email}
              onChange={setFormControl(setEmail)}
            />
            <FormInput
              id="sponsor-form-phone"
              label="Phone Number"
              type="text"
              name="phone"
              required
              value={phoneNumber}
              onChange={setFormControl(setPhoneNumber)}
            />
            <FormInput
              id="sponsor-form-company"
              label="Company"
              type="text"
              name="company"
              required
              value={company}
              onChange={setFormControl(setCompany)}
            />
            <FormInput
              id="sponsor-form-message"
              label="Message"
              type="textarea"
              name="message"
              required
              value={message}
              onChange={setFormControl(setMessage)}
            />
            <p className="legal">{COMMUNICATION_CONSENT_TEXT}</p>
            <FormInput
              id="sponsor-form-consent-to-communicate"
              label={CONSENT_TO_COMMUNICATE_CHECKBOX_TEXT}
              type="checkbox"
              name="consent_to_communicate"
              className="legal"
              value={CONSENT_TO_COMMUNICATE_CHECKBOX_TEXT}
              checked={communicationConsent}
              onChange={setFormControl(toggleCommunicationConsent)}
            />
            <p className="legal">{PROCESS_CONSENT_TEXT}</p>
            <FormInput
              id="sponsor-form-consent-to-process"
              label={CONSENT_TO_PROCESS_CHECKBOX_TEXT}
              type="checkbox"
              name="consent_to_process"
              className="legal"
              required
              value={CONSENT_TO_PROCESS_CHECKBOX_TEXT}
              checked={processConsent}
              onChange={setFormControl(toggleProcessConsent)}
            />
            <p className="legal">{PRIVACY_TEXT}</p>
            <div className="form-control">
              <ErrorBoundary message="Failed to load ReCaptcha, try navigating away and back to this page.">
                <Recaptcha
                  sitekey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
                  verifyCallback={setCaptchaResponse}
                  expiredCallback={() => setCaptchaResponse("")}
                  size="normal"
                />
              </ErrorBoundary>
            </div>
            {submitStatus === "none" && (
              <div className="form-control">
                <Button as="button">Submit</Button>
              </div>
            )}
          </form>
        </Column>
      </Row>
    </NoSideBarLayout>
  )
}

export default SponsorInterestPage
