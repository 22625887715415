export const COMMUNICATION_CONSENT_TEXT =
  "Hackathons UK Limited is committed to protecting and respecting your privacy, and we’ll only use your personal information to administer your account and to provide the products and services you requested from us. From time to time, we would like to contact you about our products and services, as well as other content that may be of interest to you. If you consent to us contacting you for this purpose, please tick below to say how you would like us to contact you:"
export const CONSENT_TO_COMMUNICATE_CHECKBOX_TEXT =
  "I agree to receive other communications from Hackathons UK Limited."
export const PROCESS_CONSENT_TEXT =
  "In order to provide you the content requested, we need to store and process your personal data. If you consent to us storing your personal data for this purpose, please tick the checkbox below."
export const CONSENT_TO_PROCESS_CHECKBOX_TEXT =
  "I agree to allow Hackathons UK Limited to store and process my personal data."
export const PRIVACY_TEXT =
  "You can unsubscribe from these communications at any time. For more information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review our Privacy Policy."
