export interface HubSpotContactFormBody {
  firstname: string
  lastname: string
  company: string
  email: string
  phone: string
  message: string
}

const mapFields = <T>(fields: T): Array<{ name: keyof T; value: string }> => {
  const outFields: ReturnType<typeof mapFields> = []

  for (const [key, value] of Object.entries(fields)) {
    outFields.push({
      name: key,
      value,
    })
  }

  return outFields
}

export const submit = async (
  body: HubSpotContactFormBody,
  consentToProcess: boolean,
  consentToCommunicate: boolean,
  consentToProcessText: string,
  consentToCommunicateText: string
) => {
  const requestObject = {
    submittedAt: Date.now(),
    fields: mapFields<HubSpotContactFormBody>(body),
    context: {
      pageUri: window.location.href,
      pageName: document.title,
    },
    legalConsentOptions: {
      consent: {
        consentToProcess: consentToProcess,
        text: consentToProcessText,
        communications: [
          {
            value: consentToCommunicate,
            subscriptionTypeId: process.env.GATSBY_HUBSPOT_ONE_TO_ONE_ID,
            text: consentToCommunicateText,
          },
        ],
      },
    },
  }

  const submissionUrl = `${process.env.GATSBY_HUBSPOT_URL}/${process.env.GATSBY_HUBSPOT_PORTAL_ID}/${process.env.GATSBY_HUBSPOT_FORM_GUID}`

  try {
    const rawResponse = await fetch(submissionUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestObject),
    })

    const response = await rawResponse.json()

    if (response.status === "error") {
      throw new Error(response.error)
    }
    return response.inlineMessage
  } catch (e) {
    throw e
  }
}
